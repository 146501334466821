<template>
  <div>
    Success
  </div>
</template>
<script>

export default {
  name: 'SignSuccess',
  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
body {
  padding: 20px;
}
</style>
